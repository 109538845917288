aside {
    margin-right: 2rem;
    background: #565769;
    height: 100%;
}

.on-hover:hover {
    cursor: pointer;
}

.demo-page-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    color: #8BA2D9;
}

.title-aside {
    width: 271px;
    height: 58px;
    background: #565769;
    display: flex;
    align-items: center;
}

.title-aside-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    color: #92A6E9;
    margin-left: 1rem;
}

.item-aside {
    width: 100%;
    height: 45px;
    background: #626274;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
}

.item-aside-usage {
    width: 100%;
    height: 45px;
    background: #333856;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
}

.item-aside-disabled {
    width: 100%;
    height: 45px;
    background: #3a3a4c;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
}

.item-aside-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9696A2;
    margin-left: 1rem;
}

.item-aside-text-usage {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #91929D;
    margin-left: 1rem;
}

.item-aside-text-disabled {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #71717d;
    margin-left: 1rem;
}

.on-hover-aside:hover {
    cursor: pointer;
    background: #39394b;
}

.plm {
    margin-left: 2rem;
}

.wrap-demo-single-text {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.demo-single-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.item-bottom-demo {
    background-size: contain;
    background-repeat: no-repeat;
    width: 347px;
    height: 337px;
    padding: 2rem;
}

.first {
    background-image: url("./images/demo/pages/first.png");
}

.second {
    background-image: url("./images/demo/pages/second.png");
}

.item-bottom-demo-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #92A6E9;
}

.item-bottom-demo-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.item-aside.active {
    background: #6D83D6;
    color: white;
}

.item-aside-text.active {
    color: white;
}

.model-name {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    color: #92A6E9;
}

.button-company {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #92A6E9;
    background: transparent;
    border: 1px solid #92A6E9;
    border-radius: 54px;
    padding: 4px;
    width: 146px;
    transition: .25s;
    height: 30px;
}

.button-company:hover {
    background: #383846;
    transition: .25s;
    box-shadow: 0px 1px 4px 2px #92a6e94d;
    color: #92a6e9;
}

.model-btn {
    width: 247px;
    height: 57px;
    border: none;
    border-radius: 7rem;
    background: #555A7B;
    color: white;
    font-size: 16px;
    transition: .25s;
    position: relative;
}

.model-btn:hover {
    background: #383846;
    box-shadow: 0px 1px 4px 2px #92a6e94d;
    transition: .25s;
}

.model-btn-system {
    width: 247px;
    height: 57px;
    border: none;
    border-radius: 1rem;
    background: #555A7B;
    color: white;
    font-size: 16px;
    transition: .25s;
    position: relative;
}

.model-btn-system:hover {
    background: #383846;
    box-shadow: 0px 1px 4px 2px #92a6e94d;
    transition: .25s;
}

.model-btn-system::after {
    content: " ";
    width: 19px;
    height: 19px;
    background-image: url("./images/demo/pages/openmodel.svg");
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
}

.model-btn::after {
    content: " ";
    width: 19px;
    height: 19px;
    background-image: url("./images/demo/pages/openmodel.svg");
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
}

.img-by-system {
    width: 5rem;
    margin-right: 2rem;
}

.title-by-system {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    background: linear-gradient(180deg, #92A6E9 0%, #6D83D6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    text-transform: uppercase;
}

.switch-btn {
    background: #5D658C;
    color: #424353;
    border: none;
    padding: 1rem;
    border-radius: 15px 15px 0 0;
    width: 8rem;
    text-transform: uppercase;
    margin-left: 0.5rem;
}

.switch-btn.active {
    background: #6D83D6;
    color: white;
}

.item-switch {
    background: #6D83D6;
    color: white;
    padding: 2rem;
    border-radius: 2rem 0 2rem 2rem;
    min-height: 14rem;
}

.parameter {
    padding: 2rem;
    background: #555A7B;
    margin-top: 2rem;
    border-radius: 2rem;
}

.select-wrapper {
    position: relative;
}

.default-case {
    border-radius: 3rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: 0px 4px 5px 2px #00000059;
    border: none;
}

.select {
    position: absolute;
    width: 14px;
    height: 12px;
    background-image: url("./images/demo/pages/openselect.svg");
    z-index: 0;
    right: 14px;
    bottom: 10px;
    background-repeat: no-repeat;
    pointer-events: none;
}

.title-input-model {
    color: #92A6E9;
    width: 11rem;
    font-size: 12px;
    height: 2.4rem;
    display: flex;
    align-items: center;
}

.input-input-model {
    background: transparent;
    border: 2px solid #68719C;
    border-radius: 8px 0 0 8px;
    text-align: center;
    color: #92A6E9;
    width: 103px;
    height: 2rem;
}

.input-input-model-data {
    background: transparent;
    border: 2px solid #68719C;
    border-radius: 8px;
    text-align: center;
    color: #92A6E9;
    min-width: 176px;
    height: 2rem;
}

.input-input-model-gender {
    background: transparent;
    border: 2px solid #68719C;
    border-radius: 8px;
    text-align: center;
    color: #92A6E9;
    min-width: 176px;
    height: 2rem;
}

.input-unit-model {
    background: transparent;
    border: 2px solid #68719C;
    border-radius: 0 8px 8px 0;
    text-align: center;
    color: #92A6E9;
    padding-left: 5px;
    padding-right: 5px;
    height: 2rem;
    margin: 0 -2px;
    line-height: 27px;
    min-width: 75px;
}

.submit-model {
    width: 427px;
    height: 39px;
    background: #6D83D6;
    border: 1px solid #92A6E9;
    border-radius: 7px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.result-model {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000b0;
    z-index: 999999999999999999;
    top: 0;
    left: 0;
}

.inset-result {
    width: 800px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.form-result {
    background: #555A7B;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 5px 4px 4px #00000070;
    padding: 2rem;
    position: relative;
}

.title-result {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #92A6E9;
}

.title-result-little {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    color: #92A6E9;
}

.title-result-second {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #92A6E9;
}

.result-model-table {
    width: 100%;
    color: #FFFFFF;
    
}

.result-model-table tbody td {
    font-size: 0.9rem;
}

.result-model-table tfoot td {
    text-align: center;
}

.result-model-table td, .result-model-table th {
    border: 1px solid #92A6E9;
    padding: 2px 10px;
}

.submit-model.disabled {
    opacity: .5;
}

.close-result {
    position: absolute;
    right: -2rem;
    top: 0;
}

.close-result:hover {
    cursor: pointer;
    opacity: .8;
}

.close-result-meta {
    position: absolute;
    right: -3rem;
    top: -4rem;
}

.close-result-meta:hover {
    cursor: pointer;
    opacity: .8;
}

.listen-result-wrap {
    background: #464A65;
    height: 4rem;
    width: 100%;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    position: relative;
}

.listen-result-container {
    height: 4rem;
    width: 90%;
    margin: 0 auto;
}

.listen-result {
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

}

.lines-green {
    height: 4px;
    background: #08D140;
}

.lines-orange {
    height: 4px;
    background: #FF9900;
}

.lines-orange-red {
    height: 4px;
    background: #be0000;
}

.lines-green-line {
    position: absolute;
    width: 4px;
    height: 20px;
    background: #08D140;
}

.lines-red-line-text {
    position: absolute;
    top: 20px;
    left: -11px;
    font-weight: bold;
    color: #FF0000;
    font-size: 14px;
    width: 25px;
    text-align: center;
}

.lines-orange-line-text {
    position: absolute;
    top: 20px;
    left: -11px;
    font-weight: bold;
    color: #FF9900;
    font-size: 14px;
    width: 25px;
    text-align: center;
}

.lines-red-final-line-text {
    position: absolute;
    top: 20px;
    left: -11px;
    font-weight: bold;
    color: #FF0000;
    font-size: 14px;
    width: 26px;
    text-align: center;
}

.lines-red-final-line-text-score {
    position: absolute;
    top: 20px;
    left: -11px;
    font-weight: bold;
    color: #be0000;
    font-size: 14px;
    width: 26px;
    text-align: center;
}

.lines-red-line {
    position: absolute;
    width: 4px;
    height: 20px;
    background: #FF0000;
}

.lines-red-line-score {
    position: absolute;
    width: 4px;
    height: 20px;
    background: #be0000;
}

.lines-red-orange {
    position: absolute;
    width: 4px;
    height: 20px;
    background: #FF9900;
}

.lines-red-orange-red {
    position: absolute;
    width: 4px;
    height: 20px;
    background: #FF0000;
}

.lines-orange-line-text-red {
    position: absolute;
    top: 20px;
    left: -2px;
    font-weight: bold;
    color: #FF0000;
    font-size: 14px;
}

.lines-red-line-red {
    position: absolute;
    width: 4px;
    height: 20px;
    background: #be0000;
}

.lines-red-line-text-red {
    position: absolute;
    top: 20px;
    left: -11px;
    font-weight: bold;
    color: #be0000;
    font-size: 14px;
    width: 25px;
    text-align: center;
}

.lines-green-line-text {
    position: absolute;
    top: 20px;
    left: -2px;
    font-weight: bold;
    color: #08D140;
    font-size: 14px;
}

.lines-red {
    height: 4px;
    background: #FF0000;
}

.lines-red-result-line-text {
    position: absolute;
    top: -8px;
    left: -22.5px;
    font-weight: 900;
    font-size: 14px;
    width: 50px;
    height: 20px;
    text-align: center;
    z-index: 999;
    border-radius: 2rem;
}

.result-line {
    position: absolute;
    width: 4px;
    height: 20px;
    top: 22px;
    left: 0px;
}

.results {
    position: absolute;
    top: 0px;
}

.marker {
    position: absolute;
}

.result-nums {
    width: 150px;
    height: 150px;
    background: white;
    border-radius: 50%;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    line-height: 150px;
}

.result-about {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.title-input-model.error-input {
    color: red;
}

.input-input-model.error-input {
    color: red;
    border-color: red;
}

.input-unit-model.error-input {
    color: red;
    border-color: red;
}

.pointer-event-none {
    pointer-events: none;
}


button:focus, input:focus, select:focus {
    outline: none;
    background: #494d66;
}

.no-pointer {
    pointer-events: none;
}

.about-default {
    background: #6d83d6;
    color: white;
    box-shadow: 2px 2px 2px black;
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 1rem;
}

.demo {
    position: relative;
}

.result-model-history {
    position: relative;
}

.form-result-history {
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.print-wrapper {
    position: fixed;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
}