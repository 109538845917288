.wrap-switcher {
    margin-left: 2rem;
    width: 38rem;
}

.wrap-meta-model-result {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000078;
    top: 0;
    left: 0;
    z-index: 999999999;
    display: flex;
    align-items: center;
}

.inset-model {
    width: 70%;
    margin: 0 auto;
    background: #555A7B;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 1px 3px 3px 3px #00000059;
}

.meta-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #92A6E9;
}

.rows {
    display: flex;
    position: relative;
}

.spider {
    width: 100%;
    padding-bottom: 1rem;
}

.item-switch-model {
    font-family: 'IBM Plex Sans', sans-serif;
    display: grid;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.1px;
    color: #333856;
    background: #CCCDD7;
    padding: 6px;
    border-radius: 12px 12px 0 0;
    width: 109px;
    height: 70px;
    transition: .25s;
}

.item-switch-model.active {
    color: #333856;
    background: #FFFFFF;
}

.switcher {
    display: flex;
    width: 100%;
}

.image-model-inset {
    width: 25px;
    margin: 6px auto;
}

.inset-switcher-back {
    padding: .5rem;
    background: #ffffff;
    border-radius: 0 0 12px 12px;
}

.inset-switcher {
    background: #FFFFFF;
    padding: 0.5rem;
    height: 33rem;
    width: 37rem;
}

.overfow-this {
    width: 36.5rem;
    height: 26rem;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 0.5rem;
}

.overfow-this::-webkit-scrollbar {
    width: 10px; /* ширина scrollbar */
}

.overfow-this::-webkit-scrollbar-track {
    background: #FFFFFF; /* цвет дорожки */
    border: 1px solid #D9D9D9;
    border-radius: 20px;
}

.overfow-this::-webkit-scrollbar-thumb {
    background-color: #D9D9D9; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 3px solid #D9D9D9; /* padding вокруг плашки */
}

.item-switch-model:hover {
    transition: .25s;
    cursor: pointer;
    filter: opacity(0.8);
}

.spider-spawn {
    width: 41rem;
    background: #333856;
    padding: .5rem;
    border-radius: 12px;
    box-shadow: 0 3px 5px #00000094;
    display: grid;
    text-align: center;
}

.sb {
    width: 28rem;
    margin: 0 auto;
}

.title-meta-result {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    display: flex;
    color: #92A6E9;
    width: 17rem;
}

.title-meta-result-history {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #92A6E9;
    text-align: center;
    width: 100%;
    padding-bottom: 2rem;
}

.meta-result-quadro-wrap {
    position: relative;
}

.meta-result-quadro {
    width: 90px;
    height: 90px;
    text-align: center;
    border: 1px solid;
    display: grid;
    padding: 6px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    background: white !important;
}

.meta-result-quadro-r {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.1px;
}

.meta-result-quadro-text {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.1px;
}

.red {
    color: #FF0000;
}

.blue {
    color: #92A6E9;
}

.wrap-pie {
    position: absolute;
    top: -12.5px;
    left: -12.5px;
    z-index: 0;
    width: 115px;
    height: 116px;
}

.title-models {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #FF0000;
}

.hide-models {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    color: #FF0000;
    position: relative;
}

.title-models-green {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #08d140;
}

.hide-models-green {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    color: #08d140;
    position: relative;
}

.hide-models::after {
    content: " ";
    width: 16px;
    height: 16px;
    background-image: url("../../../../images/demo/icon/hide.svg");
    position: absolute;
    left: 31px;
}

.hide-models.active::after {
    content: " ";
    width: 16px;
    height: 16px;
    background-image: url("../../../../images/demo/icon/hideo.svg");
    position: absolute;
    left: 25px;
}

.hide-models:hover {
    cursor: pointer;
    opacity: .8;
}

.hide-models-green::after {
    content: " ";
    width: 16px;
    height: 16px;
    background-image: url("../../../../images/demo/icon/hideg.svg");
    position: absolute;
    left: 31px;
}

.hide-models-green.active::after {
    content: " ";
    width: 16px;
    height: 16px;
    background-image: url("../../../../images/demo/icon/hidego.svg");
    position: absolute;
    left: 25px;
}

.hide-models-green:hover {
    cursor: pointer;
    opacity: .8;
}

.titless {
    font-family: 'IBM Plex Sans', sans-serif;
    background: #FFB3B3;
    color: white;
    font-size: 16px;
}

.rowses {
    font-family: 'IBM Plex Sans', sans-serif;
    background: #FFB3B3;
    color: white;
    font-size: 12px;
}

.titless-green {
    font-family: 'IBM Plex Sans', sans-serif;
    background: #08d140;
    color: black;
    font-size: 16px;
}

.rowses-green {
    font-family: 'IBM Plex Sans', sans-serif;
    background: #08d140;
    color: black;
    font-size: 12px;
}

.rowsess {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
}

.w-35 {
    width: 32% !important;
}

.wrap-meta-model-result-history {
    background: white;
}

.spider-spawn-history {
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.inset-switcher-history {
    padding: 12px;
    border: 1px solid #92a6e9;
    margin: 0.25rem;
    border-radius: 1rem;
}

.inset-switcher-back-history {
    display: flex;
    flex-wrap: wrap;
}