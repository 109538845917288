.history-item {
    border: 1px solid #848492;
    border-radius: 10px;
    height: 55px;
    width: 100%;
    margin: 10px 0 10px 0;
}

.history-item-print {
    height: 55px;
    width: 100%;
    margin: 10px 0 10px 0;
    background: #4A78BD;
}

.history-wrap {
    height: auto;
}

.section-left {
    width: 100%;
}

.d-grid {
    display: grid !important;
}

.history-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #92A6E9;
    display: flex;
    align-items: center;
    line-height: 2;
}

.history-item-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #92A6E9;
    margin-left: 2rem;
    margin-right: auto;
    width: 20rem;
}

.history-item-date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #92A6E9;
    width: 7rem;
}

.history-item-time {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #92A6E9;
    width: 5rem;
}

.history-item-add {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #848492;
    width: 100px;
    text-align: center;
    border-left: 1px solid #848492;
    height: 55px;
}

.item-print {
    margin: 0 auto;
    background: white;
    width: 190mm;
}

.history-item-show {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: #4F5064;
    background: #848492;
    transition: .5s;
    width: 90px;
    height: 55px;
}

.history-item-show.active {
    background: #92A6E9;
}

.history-item-delete {
    background: #848492;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: #4F5064;
    transition: .5s;
    width: 90px;
    height: 55px;
    border-radius: 0 10px 10px 0;
}

.history-item-show:hover {
    transition: .5s;
    cursor: pointer;
    filter: brightness(1.1);
}

.history-item-delete:hover {
    transition: .5s;
    cursor: pointer;
    filter: brightness(1.1);
}

/* для элемента input c type="checkbox" */
.history-item-check {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label, связанного с .history-item-check */
.history-item-check+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.history-item-check+label::before {
    position: absolute;
    content: '';
    display: block;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #848492;
    border-radius: 0.25em;
    margin-top: 3px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    top: 22px;
    left: 27px;
}

.history-item-check:focus {
    outline: none !important;
}

/* стили для чекбокса, находящегося в состоянии checked */
.history-item-check:checked+label::before {
    border-color: #92A6E9;
    background-color: #505164;
    background-image: url('../../images/demo/check.svg');
}

/* стили для чекбокса, находящегося в состоянии disabled */
.history-item-check:disabled+label::before {
    background-color: #e9ecef;
}

.history-item-check:checked+label {
    color: #92A6E9;
}

.label-check {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #848492;
    text-align: center;
    position: relative;
}

.a4 {
    width: 210mm;
    height: 297mm;
    background: white;
    padding: 1rem;
    margin-bottom: 1rem;
}

.print {
     color: black !important;
 }

.print-white {
    color: white !important;
}

.print-border {
    border: 1px solid;
    border-color: #bebebe !important;
}

.print-btn {
    width: 350px;
    height: 40px;
    background: #6D83D6;
    border-radius: 7px;
    color: white;
    text-align: center;
    border: none;
    display: block;
    margin: 2rem auto 6rem;
}