.loading-img {
    max-width: 100%;
    margin: 0 auto;
    align-self: center;
    animation: show 2.5s infinite;
    transform-origin: center;
}

.loading.d-flex {
    height: 100vh;
    transition: .5s;
    position: fixed;
    top: 0;
    z-index: 999999;
    background: white;
    width: 100%;
}

.loading.d-flex.active {
    transition: .5s;
    opacity: 0;
}

#root {
    display: contents;
}

@keyframes show {
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(0.8)
    }
    100% {
        transform: scale(1)
    }
}