.demo-container {
    background: #414665;
}

.demo-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    background: linear-gradient(180deg, #92A6E9 0%, #6D83D6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.wrap-first-home {
    width: 488px;
    margin-right: 67.5px;
}

.demo-text-blue {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
}

.item-demo-home {
    width: 209px;
    height: 197px;
    border: 1px solid #7C83A2;
    border-radius: 35px;
    display: grid;
    transition: .5s;
}

.item-demo-home-inset {
    width: 209px;
    height: 197px;
    border-radius: 35px;
    display: grid;
    transition: .5s;
}

.item-demo-home:hover {
    cursor: pointer;
    background: #5E5F70;
    transition: .5s;
}

.item-demo-home-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;

    color: #FFFFFF;
}

.img-item-demo-home {
    margin: 3rem auto 0;
}

.human {
    position: absolute;
    right: -11rem;
    width: 30rem;
    bottom: 9rem;
}

@media screen and (max-width: 1480px) {
    .human {
        scale: 70%;
        right: -6.5rem;
    }
}